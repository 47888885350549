import React from "react";

function GroceryItem(props) {
  function handleEvent() {
    alert(`Added ${props.name} to basket`);
  }
  return (
    <div className="product">
      <h2>{props.name}</h2>
      <p>{props.description}</p>
      <button onClick={handleEvent}>{props.name}</button>
    </div>
  );
}

export default GroceryItem;
