import React from "react";
import GroceryItem from "./GroceryItem";

function App() {
  const groceryData = [
    { name: "Eggs", description: "Farm-fresh eggs, a powerhouse of protein and essential nutrients, are the perfect start to your day. Their sunshine-yellow yolks and fluffy whites bring a touch of wholesome goodness to any breakfast creation." },
    {
      name: "Bread",
      description:
        "Savor the comforting aroma of freshly baked bread, its golden crust and soft, pillowy center a delight for your senses. Whether sliced for sandwiches, toasted with your favorite spread, or simply enjoyed as is, our bread is a timeless staple.",
    },
    { name: "Milk", description: "Pure and refreshing, our milk nourishes your body with wholesome goodness.  Its rich, creamy taste is just as delicious on its own as it is the perfect complement to your favorite cereal, coffee, or baked treats." },
    { name: "Apples", description: "Crisp, juicy, and naturally sweet, our apples burst with flavor in every bite.  A vibrant symbol of health and vitality, they make the perfect on-the-go snack or a delightful addition to salads, pies, and more." },
  ];
  return (
    <div>
      {groceryData.map((item, index) => (
        <GroceryItem key={index} name={item.name} description={item.description} />
      ))}
    </div>
  );
}

export default App;
